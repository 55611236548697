import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Typography,
  makeStyles,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  AppBar,
  IconButton,
  Dialog,
  Toolbar,
  Tooltip,
  Divider,
  Fab,
  InputAdornment,
  Icon,
  darken,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import Feedback from "../../../atoms/Feedback";

import { getCurrencies } from "../../../../actions/currencyActions";
import { getCostCodesByType } from "../../../../actions/costCodeAction";
import { formatAmount } from "../../../../utils";
import { getSymbol, getCode } from "../../../../utils/currencyconvert";
// import { getAllMonthlyBudgetItems } from "../../../actions/monthlyBudgetAction";
import { getDivisionYearAnnualBudgetLines } from "../../../../actions/annualAction";
import { getDivisions } from '../../../../actions/divisionActions';

import { useHistory } from "react-router-dom";
import {
  uploadDoc,
  createReimbursement,
  getAllReimbursements,
} from "../../../../actions/reimburseActions";

const useStyles = makeStyles((theme) => ({
  title: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
  },
  title2: {
    display: "block",
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 1.5,
    fontFamily: "Rubik",
    marginTop: 20,
  },
  appBar: {
    // position: "relative",
    zIndex: 9000,
  },
  container: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(4),
    },
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  option: {
    fontSize: 15,
    "& > span": {
      marginRight: 10,
      fontSize: 18,
    },
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  paper2: {
    padding: "2px 20px 2px 5px",
    width: "60%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "100%",
    },
    "&:nth-of-type(2n)": {
      color: theme.palette.background.paper,
      backgroundColor: theme.palette.primary.main,
    },
  },
  dialogContent: {
    [theme.breakpoints.down("sm")]: {
      padding: "8px 14px",
    },
  },
  tabPanel: {
    padding: 14,
  },
  divider2: {
    height: 28,
    margin: 4,
  },
  input: {
    display: "none",
  },
  submitBtn: {
    marginLeft: 10,
    // padding: theme.spacing(2),
    background: "#388e3c",
    color: "#fff",
    "&:hover": {
      background: darken("#388e3c", 0.15),
      // color: "#fff",
    },
  },
  total: {
    marginTop: 40,
  },
  form: {
    marginTop: 30,
  },
  saveBtn: {
    margin: "30px 0",
    padding: theme.spacing(2),
    fontFamily: "Rubik",
    letterSpacing: 2.5,
    width: 300,
  },
}));

const INIT_STATE = {
  employeeName: "",
  employeeId: "",
  division: "",
  divisionId: "",
  month: moment().format("MMMM"),
  year: moment().format("YYYY"),
  purpose: "",
  costType: "",
  costCenter: "",
  rate: 1,
  serviceDivision: "",
  // amountToReimburse: 0,
};

const INIT_BREAKDOWN = {
  item: "",
  amount: 0,
  description: "",
};

const CreateReimbursementModal = ({
  openCreate,
  handleCreateClose,
  getCurrencies,
  currencies,
  getCostCodesByType,
  costcodes,
  getDivisions,
  divisions,
  // getAllMonthlyBudgetItems,
  getDivisionYearAnnualBudgetLines,
  annualBudgetLines,
  profile,
  // items,
  uploadDoc,
  createReimbursement,
  getAllReimbursements,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState(INIT_STATE);
  const [total, setTotal] = useState(0);

  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [loadUpload, setLoadUpload] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [docs, setDocs] = useState([]);
  const [doc, setDoc] = useState(null);

  const [breakdown, setBreakdown] = useState({
    lists: [INIT_BREAKDOWN],
  });

  const [feed, setFeed] = useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      employeeName: profile && profile.userName,
      employeeId: profile && profile.employeeId,
      division: profile && profile.division && profile.division.name,
      divisionId: profile && profile.division && profile.division._id,
    }));
  }, [profile]);

  useEffect(() => {
    setLoad((prev) => !prev);
    getCurrencies().then(() => {
      setLoad(false);
    });
  }, [getCurrencies]);

  useEffect(() => {
    getDivisions();
  }, [getDivisions]);

  const codeTypeCallback = useCallback(() => {
    setLoad((prev) => !prev);
    if (state.costType !== "") {
      getCostCodesByType(state.costType).then(() => {
        setLoad(false);
      });
    }
  }, [getCostCodesByType, state]);

  // const getAllMonthlyBudgetItemsCallback = useCallback(() => {
  //   let data = {
  //     year: state.year,
  //     month: state.month,
  //   };
  //   getAllMonthlyBudgetItems(data);
  // }, [getAllMonthlyBudgetItems, state]);

  // useEffect(() => {
  //   getAllMonthlyBudgetItemsCallback();
  // }, [getAllMonthlyBudgetItemsCallback]);

  
  const getDivisionYearAnnualBudgetLinesCallback = useCallback(() => {
    let data = {
      year: state.year,
      division: state.serviceDivision || state.divisionId,
    };
    getDivisionYearAnnualBudgetLines(data);
  }, [getDivisionYearAnnualBudgetLines, state]);

  useEffect(() => {
    getDivisionYearAnnualBudgetLinesCallback();
  }, [getDivisionYearAnnualBudgetLinesCallback]);

  useEffect(() => {
    codeTypeCallback();
  }, [codeTypeCallback]);

  useEffect(() => {
    let total = breakdown.lists.reduce((acc, cur) => {
      return acc + +cur.amount;
    }, 0);
    setTotal(total);
  }, [breakdown]);

  const navigateById = (path, id) => {
    history.push(`${path}/${id}`);
  };

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFile = (e) => {
    e.persist();
    const { files } = e.target;
    setDoc(files[0]);
  };

  const uploadDocument = () => {
    setLoadUpload((prev) => !prev);
    let formData = new FormData();
    formData.append("doc", doc);
    uploadDoc(formData)
      .then((res) => {
        setLoadUpload(false);
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Document uploaded successfully.`,
          success: true,
        });
        setDocs((prev) => [...prev, res]);
        setDoc(null);
      })
      .catch(() => {
        setFeed({
          loading: false,
          open: !feed.open,
          message: `Failed to upload document.`,
          success: true,
        });
      });
  };

  const isServices = profile ? profile.division && ((profile.division.code === "SER") ||
    (profile.division.code === "PMG") || (profile.division.code === "LAC")) : false;

  let code = getCode(state.rate, currencies);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading((prev) => !prev);
    let items = breakdown.lists.map((item) => {
      let amount;
      if (item.amount) {
        amount = +item.amount;
      }
      return {
        ...item,
        amount: amount,
      };
    });
    const data = {
      year: state.year,
      month: state.month,
      purpose: state.purpose,
      costCenter: state.costCenter,
      costType: state.costType,
      amountToReimburse: total,
      rate: state.rate,
      currency: code,
      documents: docs,
      advanceDivision: state.serviceDivision || state.divisionId,
      items,
    };
    createReimbursement(data)
      .then((doc) => {
        if (!doc.success) {
          setLoading(false);

          setFeed({
            loading: false,
            open: !feed.open,
            message: "Failed to Save Reimbursement",
            success: false,
          });
        }
        setState((prev) => ({
          ...prev,
          purpose: "",
          costType: "",
          costCenter: "",
        }));
        setBreakdown({
          lists: [INIT_BREAKDOWN],
        });

        setFeed({
          loading: false,
          open: !feed.open,
          message: `Reimbursement Saved Successfully`,
          success: true,
        });
        dispatch(getAllReimbursements);
        setTimeout(() => {
          navigateById("/reimbursement", doc.data._id);
        }, 1500);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        handleCreateClose();
      });
  };

  const handleChangeBreakdown = (i) => (e) => {
    e.persist();
    const { files } = e.target;

    const newBreakdown = breakdown.lists.map((item, index) => {
      if (i !== index) {
        return item;
      }
      setIsValid(!!e.target.value);
      return {
        ...item,
        [e.target.name]:
          e.target.name === "document" ? files[0] : e.target.value,
      };
    });
    setBreakdown({
      lists: newBreakdown,
    });
  };

  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const handleAddBreakdown = () => {
    let data = {
      item: "",
      amount: 0,

      description: "",
      document: "",
    };

    setBreakdown({
      lists: breakdown.lists.concat([data]),
    });
  };
  const handleRemoveBreakdown = (i) => {
    setBreakdown((prev) => ({
      ...prev,
      lists: prev.lists.filter((_, index) => index !== i),
    }));
  };

  const validCheck = () =>
    !state.year ||
    !state.month ||
    !state.purpose ||
    !state.costCenter ||
    isValid === false;

  // console.log(breakdown.lists);
  // console.log(docs);
  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}

      <Dialog
        open={openCreate}
        onClose={handleCreateClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        fullScreen
        maxWidth="md"
        // fullScreen={fullScreen}
      >
        <AppBar
          className={classes.appBar}
          variant="elevation"
          position="fixed"
          color="default"
        >
          <Toolbar>
            <Tooltip title="close" arrow>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleCreateClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Typography variant="overline" className={classes.title}>
            Cash Reimbursement Form
          </Typography>

          <form
            component={Paper}
            onSubmit={handleSubmit}
            className={classes.form}
          >
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item xs={12} md={3}>
                <TextField
                  label="Employee Name"
                  name="employeeName"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.employeeName || ""}
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="EID"
                  name="employeeId"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.employeeId || ""}
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Division/Unit"
                  name="division"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.division || ""}
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Month"
                  name="month"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.month || ""}
                  required
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Year"
                  name="year"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.year || ""}
                  disabled
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Purpose for Reimbursement"
                  name="purpose"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={state.purpose || ""}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel id="costTypeId">Cost Type</InputLabel>
                  <Select
                    labelId="costTypeId"
                    name="costType"
                    inputProps={{
                      id: "costType",
                      name: "costType",
                    }}
                    onChange={handleChange}
                    value={state.costType || ""}
                  >
                    <MenuItem value="project">Project</MenuItem>
                    <MenuItem value="division">Division/Unit</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl fullWidth variant="outlined" required>
                  <InputLabel id="costCodeId">Cost Center</InputLabel>
                  <Select
                    labelId="costCodeId"
                    name="costCenter"
                    inputProps={{
                      id: "costCenter",
                      name: "costCenter",
                    }}
                    onChange={handleChange}
                    value={state.costCenter || ""}
                    disabled={!state.costType}
                  >
                    <MenuItem value="" disabled>
                      <em>---SELECT COST CENTER----</em>
                    </MenuItem>
                    {costcodes &&
                      costcodes.map((cost) => (
                        <MenuItem key={cost._id} value={cost.name}>
                          {cost.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>

              {(isServices) ? (
                <>
                  <Grid item xs={12} md={12}>
                    <Typography variant="overline" className={classes.title}>
                      Cash Reimbursement Division:  Only select this field if you are raising reimbursement 
                      for another subdivision
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="serviceDivision">Cash Reimbursement Division</InputLabel>
                      <Select
                        labelId="serviceDivision"
                        name="serviceDivision"
                        inputProps={{
                          id: "serviceDivision",
                          name: "serviceDivision",
                        }}
                        onChange={handleChange}
                        value={state.serviceDivision || ""}
                      // disabled={!isServices}
                      >
                        <MenuItem value="" disabled>
                          <em>---SELECT REIMBURSEMENT DIVISION ----</em>
                        </MenuItem>
                        {divisions &&
                          divisions.map((sub) => (
                            <MenuItem key={sub._id} value={sub._id}>
                              {sub.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={8}></Grid>
                </>
              ) : null}

              <Grid item xs={12} md={12}>
                <Divider />
                <Typography variant="overline" className={classes.title2}>
                  Line Items
                </Typography>
              </Grid>

              <Grid container justify="space-between" alignItems="center">
                <Grid item>
                  <label htmlFor="document">
                    <Tooltip arrow title="Select File Attachment">
                      <Fab color="primary" component="span">
                        <Icon className="fas fa-paperclip" />
                      </Fab>
                    </Tooltip>
                  </label>

                  <input
                    id="document"
                    name="document"
                    type="file"
                    accept="image/*,.pdf"
                    className={classes.input}
                    onChange={handleFile}
                    // onChange={handleChangeBreakdown(i)}
                  />

                  <Button
                    color="primary"
                    variant="contained"
                    className={classes.submitBtn}
                    endIcon={
                      <Icon className="fas fa-upload" fontSize="small" />
                    }
                    size="small"
                    disabled={!doc || loadUpload}
                    onClick={uploadDocument}
                  >
                    {loadUpload ? "Loading..." : "Upload"}
                  </Button>
                  <ul style={{ padding: 10 }}>
                    {docs.map((doc, i) => (
                      <Paper
                        key={i}
                        component="li"
                        style={{ padding: 10, marginBottom: 5 }}
                      >
                        <a href={doc} target="_blank" rel="noopener noreferrer">
                          {doc}
                        </a>
                      </Paper>
                    ))}
                  </ul>
                </Grid>
                <Grid item style={{ marginRight: 10 }}>
                  <FormControl
                    style={{ width: 130 }}
                    variant="outlined"
                    required
                  >
                    <InputLabel id="currencyId">Currency</InputLabel>
                    <Select
                      labelId="currencyId"
                      name="rate"
                      inputProps={{
                        id: "rate",
                        name: "rate",
                      }}
                      value={state.rate || 1}
                      onChange={handleChange}
                    >
                      {currencies &&
                        currencies.map((currency) => (
                          <MenuItem key={currency._id} value={currency.rate}>
                            {currency.code}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {breakdown.lists.map((item, i) => (
                <Grid
                  container
                  spacing={3}
                  key={i}
                  justify="center"
                  alignItems="center"
                  style={{ marginRight: 10, marginTop: 10, marginLeft: 5 }}
                >
                  <Grid item xs={12} md={4}>
                    <FormControl fullWidth variant="outlined" required>
                      <InputLabel id="itemId">Expense Category</InputLabel>
                      <Select
                        labelId="itemId"
                        name="item"
                        inputProps={{
                          id: "item",
                          name: "item",
                        }}
                        value={item.item || ""}
                        onChange={handleChangeBreakdown(i)}
                      >
                        <MenuItem value="" disabled>
                          <em>---SELECT ANNUAL BUDGET EXPENSE CATEGORY----</em>
                        </MenuItem>
                        {/* {items &&
                          items.map((item) => (
                            <MenuItem key={item.item._id} value={item.item._id}>
                              {item.item.name}
                            </MenuItem>
                          ))} */}
                          {annualBudgetLines &&
                          annualBudgetLines.map((row, i) => (
                            <MenuItem key={i} value={row?.item?._id}>
                              {row?.item?.name} {" - "} {formatAmount(row.subTotal) || ""}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Amount"
                      name="amount"
                      variant="outlined"
                      fullWidth
                      type="number"
                      value={item.amount || ""}
                      onChange={handleChangeBreakdown(i)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      label="Description"
                      name="description"
                      variant="outlined"
                      fullWidth
                      value={item.description || ""}
                      onChange={handleChangeBreakdown(i)}
                      required
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <Tooltip arrow title="Remove">
                      <Fab
                        size="small"
                        color="secondary"
                        onClick={() => handleRemoveBreakdown(i)}
                      >
                        <DeleteIcon />
                      </Fab>
                    </Tooltip>
                  </Grid>
                </Grid>
              ))}
              <Grid
                item
                xs={12}
                md={12}
                style={{ marginTop: 20, marginLeft: 20 }}
              >
                <Button
                  variant="outlined"
                  color="default"
                  onClick={() => handleAddBreakdown(breakdown.length + 1)}
                  endIcon={<AddIcon fontSize="large" />}
                >
                  Insert Item
                </Button>
              </Grid>
            </Grid>
            <Grid container justify="space-between" alignItems="center">
              <Grid item></Grid>
              <Grid item>
                <TextField
                  label="Total Amount To Reimburse"
                  variant="outlined"
                  fullWidth
                  value={formatAmount(total) || 0}
                  className={classes.total}
                  disabled
                  InputProps={{
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          {/* &#8358; */}
                          {getSymbol(code, total)}
                        </InputAdornment>
                        <Divider
                          className={classes.divider2}
                          orientation="vertical"
                        />
                      </>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 30, marginRight: 10 }}
                  disabled={validCheck() || loading || load}
                  className={classes.saveBtn}
                >
                  {loading ? "Loading..." : "Save"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  currencies: state.currency.currencies,
  costcodes: state.costcode.costcodes && state.costcode.costcodes.data,
  profile: state.user.profile,
  // items: state.monthlyBudget.items,
  annualBudgetLines: state.annualBudget.annualBudgetLines,
  divisions: state.division.divisions,
});

const mapDispatchToProps = {
  getCostCodesByType,
  getCurrencies,
  // getAllMonthlyBudgetItems,
  getDivisionYearAnnualBudgetLines,
  uploadDoc,
  createReimbursement,
  getAllReimbursements,
  getDivisions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReimbursementModal);
