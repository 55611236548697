import React, { useEffect, useState } from "react";
import {
    makeStyles,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Typography,
    Divider,
    IconButton,
    ButtonGroup,
    Select,
    Button,
    MenuItem,
    DialogActions,
    TextField,
    darken,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { connect, useDispatch, useSelector } from "react-redux";
import Feedback from "../../../../atoms/Feedback";
import { getRequisitions, returnRequisition, getAllInventoryItems, } from "../../../../../actions/inventoryActions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    title: {
        display: "block",
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        fontWeight: "bold"
    },
    title2: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    title3: {
        display: "block",
        fontSize: 20,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
    },
    appBar: {
        // position: "relative",
        zIndex: 9000,
    },
    container: {
        marginTop: theme.spacing(6),
        padding: theme.spacing(6),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
        },
    },
    paper: {
        padding: theme.spacing(4),
        marginBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
        },
    },
    option: {
        fontSize: 15,
        "& > span": {
            marginRight: 10,
            fontSize: 18,
        },
    },
    textField: {
        marginBottom: theme.spacing(3),
    },
    paper2: {
        padding: "2px 20px 2px 5px",
        width: "60%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(1),
            width: "100%",
        },
        "&:nth-of-type(2n)": {
            color: theme.palette.background.paper,
            backgroundColor: theme.palette.primary.main,
        },
    },
    dialogContent: {
        paddingBottom: 30,
        [theme.breakpoints.down("sm")]: {
            padding: "8px 14px",
        },
    },
    tabPanel: {
        padding: 14,
    },
    divider: {
        marginBottom: 10,
    },
    divider2: {
        height: 28,
        margin: 4,
    },
    total: {
        marginTop: 40,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    warningInfo: {
        color: "#f5f5f5",
        background: "#f44336",
        padding: 20,
        marginBottom: 20,
        marginRight: 10,
        "& > *": {
            fontWeight: 900,
        },
    },
    form: {
        marginBottom: 20,
    },
    grid: {
        marginBottom: 20,
    },
    input: {
        border: "1px solid #bdbdbd",
        padding: theme.spacing(2),
        borderRadius: 3,
    },

    btnWrapper: {
        textAlign: "center",
    },
    saveBtn: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    saveBtn2: {
        fontFamily: "Rubik",
        letterSpacing: 2.5,
        margin: "30px 0",
        padding: theme.spacing(2),
        width: 200,
        background: "#388e3c",
        color: "#fff",
        "&:hover": {
            background: darken("#388e3c", 0.15),
            // color: "#fff",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        "&:disabled": {
            background: "#ccc",
            color: "#333",
        },
        "&:disabled:hover": {
            background: "#cccccc",
        },
    },
    small: {
        display: "block",
        fontSize: 12,
        lineHeight: 1.5,
        letterSpacing: 1.5,
        fontFamily: "Rubik",
        color: "#444",
        fontWeight: "bold",
    },
}));

const INIT_STATE = {
    items: [{
        item: "",
        quantityRequested: 1,
        quantityIssued: '',
        quantityReceived: '',
        quantityReturned: '',
        condition: "Good",
        toBeReturned: "Not To Be Returned",
    }]
};

const ReturnRequisitionModal = ({ openReturn, handleCloseReturn, id, returnRequisition, requisition }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { error, loading } = useSelector((state) => state.requisition);
    const { invItems } = useSelector((state) => state.inventoryItem);
    const [comment, setComment] = useState("");
    const [state, setState] = useState(INIT_STATE);

    const [feed, setFeed] = useState({
        loading: false,
        open: false,
        message: "",
        success: false,
    });

    useEffect(() => {
        dispatch(getAllInventoryItems());
    }, [dispatch]);

    useEffect(() => {
        if (error && error !== null) {
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: error,
                success: false,
            }));
        }
    }, [error]);

    // set requisition items to state with quantityReturned added
    useEffect(() => {
        if (requisition.items && requisition.items.length > 0) {
            const newItems = requisition && requisition.items.map((item) => {
                return {
                    ...item,
                    quantityReturned: item && item.quantityReceived,
                }
            });
            setState((prev) => ({
                ...prev,
                items: newItems
            }))
        }
    }, [requisition]);

    const handleChange = (e) => {
        e.persist();
        setComment(e.target.value);
    };

    // capture quantityReturned changes
    const handleChangeQuantity = (i) => (e) => {
        e.persist();
        const newReqItems = state.items.map((item, index) => {
            if (i !== index) {
                return item;
            }
            return {
                ...item,
                [e.target.name]: parseInt(e.target.value) || 1,
            };
        });
        setState((prev) => ({
            ...prev,
            items: newReqItems,
        }));
    };

    const handleReturn = async () => {
        const data = {
            comment,
            id,
            items: state.items,
            path: `inventory/requisition/view/${id}`,
        };
        const res = await new Promise((resolve, reject) => {
            resolve(returnRequisition(data));
        });
        if (res) {
            setTimeout(() => {
                handleCloseReturn();
            }, 1500);
            dispatch(getRequisitions());
            setFeed((prev) => ({
                loading: false,
                open: !prev.open,
                message: "Requisition Returned Successfully.",
                success: true,
            }));
            setTimeout(() => {
                history.push("/inventory/requisition");
            }, 2000);
        }
    };

    const handleCloseFeed = () => {
        setFeed((prevState) => ({ ...prevState, open: false }));
    };

    const validCheck = () =>
        (state.items.length === 0) ||
        !comment;

    return (
        <div>
            {feed.success ? (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="success"
                    message={feed.message}
                />
            ) : (
                <Feedback
                    handleCloseFeed={handleCloseFeed}
                    open={feed.open}
                    severity="error"
                    message={feed.message}
                />
            )}
            <Dialog
                open={openReturn}
                onClose={handleCloseReturn}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="overline" className={classes.title}>
                                Return Inventory Items
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCloseReturn}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {state.items &&
                        state.items.map((item, i) => (
                            <Grid container spacing={2} className={classes.grid} key={i}>
                                <Grid item xs={12} md={4} >
                                    <FormControl fullWidth required variant="outlined">
                                        <label style={{ fontWeight: "bold" }}>Inventory Item</label>
                                        <Select
                                            value={item.item || ""}
                                            disabled
                                        >
                                            <MenuItem disabled>Select</MenuItem>
                                            {invItems &&
                                                invItems.map((item) => (
                                                    <MenuItem key={item._id} value={item._id}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} >
                                    <label style={{ fontWeight: "bold" }}>Quantity Requested</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        type="number"
                                        name="quantityRequested"
                                        value={item.quantityRequested ?? ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} >
                                    <label style={{ fontWeight: "bold" }}>Quantity Issued</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        type="number"
                                        name="quantityIssued"
                                        value={item.quantityIssued ?? ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} >
                                    <label style={{ fontWeight: "bold" }}>Quantity Received</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        type="number"
                                        name="quantityReceived"
                                        value={item.quantityReceived ?? ""}
                                        disabled
                                    />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <label style={{ fontWeight: "bold" }}>Quantity Returned</label>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        required
                                        type="text"
                                        name="quantityReturned"
                                        value={item.quantityReturned ?? ""}
                                        onChange={handleChangeQuantity(i)}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    <Grid item xs={12} md={12}>
                        <label style={{ fontWeight: "bold" }}>Comment</label>
                        <TextField
                            fullWidth
                            variant="outlined"
                            label="Comment"
                            value={comment}
                            name="comment"
                            onChange={handleChange}
                            multiline
                            disabled={loading}
                            rows={4}
                            required
                            helperText={
                                <Typography variant="overline" className={classes.small}>
                                    Drop a comment before Returning the Requisition Items.
                                    Enter the quantity of each item you Returned.
                                </Typography>
                            }
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button onClick={handleCloseReturn} className={classes.saveBtn}>
                            Close
                        </Button>
                        <Button
                            className={classes.saveBtn2}
                            disabled={loading || validCheck()}
                            onClick={handleReturn}
                        >
                            {loading ? "Loading..." : "Return"}
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default connect(null, { returnRequisition })(ReturnRequisitionModal);
